import React from "react";
import { Card, Modal, Button } from "react-bootstrap";
//import './CardComponent.scss';

/**
 * CardWithImageComponent displays a card with an image that opens a modal when clicked.
 *
 * @class CardWithImageComponent
 * @extends React.Component
 *
 * @param {Object} props - The component props.
 * @param {string} props.id - The unique identifier for the component.
 * @param {string} props.className - Additional class names for custom styling.
 * @param {string} props.image - The URL of the image to be displayed.
 * @param {string} props.header - The header content of the card.
 * @param {string} props.title - The title of the card and modal.
 * @param {string} props.text - The main text content of the card.
 * @param {JSX.Element} props.content - Additional content to be rendered inside the card body.
 * @param {JSX.Element} props.footer - The footer content of the card.
 *
 * @state {boolean} showModal - Indicates if the modal is currently visible.
 *
 * @example
 * <CardWithImageComponent
 *   id="card1"
 *   className="custom-card"
 *   image="path/to/image.jpg"
 *   header="Card Header"
 *   title="Card Title"
 *   text="This is some text."
 *   content={<div>Additional Content</div>}
 *   footer={<div>Card Footer</div>}
 * />
 */
class CardWithImageComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
    this.handleImageClick = this.handleImageClick.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  /**
   * Opens the modal when the image is clicked.
   */
  handleImageClick() {
    this.setState({ showModal: true });
  }

  /**
   * Closes the modal when the close button is clicked.
   */
  handleCloseModal() {
    this.setState({ showModal: false });
  }

  /**
   * Renders the CardWithImageComponent.
   * @returns {JSX.Element} The rendered component.
   */
  render() {
    const { id, className, image, header, title, text, content, footer } =
      this.props;
    const { showModal } = this.state;

    return (
      <div id={id} className={className}>
        <Card className="card-with-image">
          <Card.Header>{header}</Card.Header>
          <Card.Img
            variant="center"
            src={image}
            onClick={this.handleImageClick}
          />
          <Card.Body>
            <Card.Title>{title}</Card.Title>
            <Card.Text className="card-text">{text}</Card.Text>
            {content}
          </Card.Body>
          <Card.Footer>{footer}</Card.Footer>
        </Card>
        <Modal
          show={showModal}
          onHide={this.handleCloseModal}
          size="xl"
          centered
          className="modal-card"
        >
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img src={image} alt={title} className="img-fluid" />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleCloseModal}>
              Schließen
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default CardWithImageComponent;
