import React from "react";
import { Card, Modal, Button } from "react-bootstrap";
//import './CardComponent.scss';

/**
 * CardWithVideoComponent displays a card with a video thumbnail that opens a modal to play the video.
 *
 * @class CardWithVideoComponent
 * @extends React.Component
 *
 * @param {Object} props - The component props.
 * @param {string} props.id - The unique identifier for the component.
 * @param {string} props.className - Additional class names for custom styling.
 * @param {string} props.videoUrl - The URL of the video to be displayed.
 * @param {string} props.header - The header content of the card.
 * @param {string} props.title - The title of the card and modal.
 * @param {string} props.text - The main text content of the card.
 * @param {JSX.Element} props.content - Additional content to be rendered inside the card body.
 * @param {JSX.Element} props.footer - The footer content of the card.
 *
 * @state {boolean} showModal - Indicates if the modal is currently visible.
 *
 * @example
 * <CardWithVideoComponent
 *   id="videoCard1"
 *   className="custom-card"
 *   videoUrl="https://www.youtube.com/embed/dQw4w9WgXcQ"
 *   header="Video Header"
 *   title="Video Title"
 *   text="This is some descriptive text."
 *   content={<div>Additional Content</div>}
 *   footer={<div>Card Footer</div>}
 * />
 */
class CardWithVideoComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
    this.handleVideoClick = this.handleVideoClick.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  /**
   * Opens the modal when the video thumbnail is clicked.
   */
  handleVideoClick() {
    this.setState({ showModal: true });
  }

  /**
   * Closes the modal when the close button is clicked.
   */
  handleCloseModal() {
    this.setState({ showModal: false });
  }

  /**
   * Renders the CardWithVideoComponent.
   * @returns {JSX.Element} The rendered component.
   */
  render() {
    const { id, className, videoUrl, header, title, text, content, footer } =
      this.props;
    const { showModal } = this.state;

    return (
      <div id={id} className={className}>
        <Card className="card-with-video">
          <Card.Header>{header}</Card.Header>
          <div onClick={this.handleVideoClick} className="video-thumbnail">
            <iframe
              width="100%"
              height="315"
              src={videoUrl}
              title={title}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>

          <Card.Body>
            <Card.Title>{title}</Card.Title>
            <Card.Text className="card-text">{text}</Card.Text>
            {content}
          </Card.Body>
          <Card.Footer>{footer}</Card.Footer>
        </Card>
        <Modal
          show={showModal}
          onHide={this.handleCloseModal}
          size="xl"
          centered
          className="modal-card"
        >
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <iframe
              width="100%"
              height="500"
              src={videoUrl}
              title={title}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleCloseModal}>
              Schließen
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default CardWithVideoComponent;
